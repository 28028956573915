<template>
  <div class="pay-success">
      <div class="w bread">
        {{this.$t('location')}} > <a @click="returnIndex">{{this.$t('homePage')}} > </a><a>{{this.$t('paySuccess')}}</a>
      </div>
      <div class="w pay-content">
          <div class="icon"><img src="@/assets/img/102.png" alt=""></div>
          <div class="order-info" >
          <div class="price">{{this.$t('successPay')}}<span>￥{{orderInfo.orderTotal}}</span>，{{this.$t('speed')}}</div>
          <div class="address"><span>{{this.$t('Harvest')}}：</span>{{orderInfo.recReginName}} {{orderInfo.recAddress}}</div>
          <div class="consignee"><span>{{this.$t('consignee')}}：</span>{{orderInfo.recName}}</div>
          <div class="contact"><span>{{this.$t('contactNum')}}：</span>{{orderInfo.recMobile}}</div>
          <div class="line"></div>
          <div class="order-content">
              <div class="order-num">
                  <ul>
                      <li><span>{{this.$t('orderNum')}}：</span><span class="value">{{orderInfo.orderCode}}</span><span class="bar">|</span></li>
                      <li><span>{{this.$t('payMethods')}}：</span><span class="value" v-for="(v,index) in payMethods" :key="index"><img :src="v.pictrueUrl" alt="" class="Img">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{v.name}}</span><span class="bar">|</span></li>
                      <li><span>{{this.$t('payAmount')}}：</span><span class="value">￥{{orderInfo.orderTotal}}</span></li>
                  </ul>
              </div>
              <div class="button">
                  <button class="return" @click="returnOrder">{{this.$t('returnOrder')}}</button>
                  <button class="see" @click="viewOder">{{this.$t('ViewOrder')}}</button>
              </div>
          </div>
          </div>
      </div>
  </div>
</template>

<script>
import {orderDetail} from '../shoppingCart/model'
import { showLayer } from "@/utils/com";
export default {
    data(){
        return {
            owid:'',
            orderInfo:'',
            payMethods:[]// 支付方式
        }
    },
    created(){
        this.owid = this.$route.query.owid;
        orderDetail({owid:this.owid}).then(res=>{
            this.orderInfo = res;
             switch(this.orderInfo.orderPayWay){
                case 1 :
                     this.payMethods.push({name:this.$t('Alipay1'),pictrueUrl:require('@/assets/img/104.png')});
                     break;
                case 2 :
                    this.payMethods.push({name:this.$t('weChatPay'),pictrueUrl:require('@/assets/img/103.png')});
                    break;
                case 3 : 
                    this.payMethods.push({name:this.$t('UnionPay'),pictrueUrl:require('@/assets/img/105.png')});
                    break;
                default :
                     showLayer("toast", "订单还未支付！！");
            }
           
        })
       
    },
    methods:{
        // 返回订单页面
        returnOrder(){
            this.$router.push('/profile/order')
        },
        //返回首页
        returnIndex(){
            this.$router.push('/')
        },
        viewOder(){
            this.$router.push({path:'/viewOrder',query:{owid:this.owid}})
        }
    }
}
</script>

<style>
.pay-success {
    margin-top: 0.1rem;
}
    .pay-content {
        display: flex;
        width: 100%;
        height: 348px;
        border: 1px solid #e5e5e5;
        margin-top: .1rem;
        margin-bottom: .1rem;
        padding: 35px 0 56px 59px;
    }
    .icon {
        width: 44px;
        height: 30%;
        display: inline-block;
        margin-right: .1rem;
    }
    .icon img {
        width: 44px;
        height: 44px;
    } 
    .order-info {
        flex: 1;
        display: inline;
    }
    .price {
        display: inline;
        width: 100%;
        height: .1rem;
        line-height: .1rem;
        margin-top: -10px;
        font-size: 20px;
    } 
    .price span {
        color: #EF535D;
    }
    .address , .consignee, .contact {
        margin-top: .08rem;
        width: 100%;
        height: 15px;
        line-height: 15px;
        color: #333;
    }
     .consignee span ,
     .contact span,
     .address span,
     .order-num span {
        color: #000;
    } 
    
    .line {
        margin-top: .1rem;
        margin-left: -.3rem !important;
        width:100%;
        height:1px;
        line-height:1px;
        overflow:hidden;
        font-size:0px;
        margin-left:10px;
        border-bottom: solid 1px #e5e5e5;
    }
    .order-content {
        height: 0.5rem;
        line-height: 15px;
        width: 100%;
    } 
   .order-num {
        margin-top: .1rem;
        height: 15px;
        line-height: 15px;
        color: #333;
        width: 100%;
    }
    .bar {
        margin: 0 .05rem;
        color: #e5e5e5;
        
    }
    .order-num li {
        float: left;
        display: block;
        height: 16px;
        
        
    }
    .order-num .value {
        color: #707070;
        font-weight: 600;
    }
    .button {
        margin-top: .3rem;
        width: 100%;
        height: .2rem;
    }
    .value{
       position: relative;
    }
    .Img {
         width: 16px;
        height: 16px;
        display: inline-block;
        position: absolute;
        top: 2px;
        left: 0;
    }
    .return {
        width: 150px;
        height: 40px;
        background: #fff;
        border: 1px solid #32B16C;
        border-radius: 2px;
        font-size: 16px;
        color: #32B16C;
        margin-right: .1rem;
        cursor: pointer;
    }
    .see {
        width: 150px;
        height: 40px;
        background: #EF535D;
        border: 1px solid #EF535D;
        border-radius: 2px;
        font-size: 16px;
        color: #fff;
        margin-right: 0.1rem;
        cursor: pointer;
    }
</style>